import React from "react";

import LessonLayout from "~/layouts/lesson";

import Accordion from "~/components/accordion";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={7}
        lesson={"Lesson 3"}
        color={"hs"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Step 7</H2>

          <p>In this lesson, you have learned some ways that marketers try to persuade people to buy or use products or to believe in their causes. You have also practiced your critical thinking skills to analyze information.</p>

          <p>Open the rubric you have been working on. You will now work on the category "Lesson 3 Persuasion and Relevance".</p>

          <p>Work with your teammates to add statements to your rubric that relate to what you have learned in this lesson.</p>

          <Accordion
            headerText="Hint"
            open={false}
          >
            <p className="mt-0">You may not need all the spaces in the rubric. It is OK if you leave some blank. Keep in mind that you can make changes to any category of your rubric at any time. If you have new ideas to add to the earlier categories, you can add them now or change things you added earlier.</p>
          </Accordion>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
